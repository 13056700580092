import classes from "./FooterInfo.module.css";
import { MENU_HEADER, NAIL_SHOP_INFO, SOLCO_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import { menuHeaderHandler } from "../../navigation/MenuHeaderHandler";
import { useNavigate } from "react-router-dom";

const FooterInfo = (props) => {
  const { style } = props;
  const isMobile = useCheckIsMobile();
  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.container} ${style}`}>
        
        <div className={classes.columnInfo} style={{flex:4}}>
          <div className={classes.address}>
            <h2>{NAIL_SHOP_INFO.name}</h2>
            <a
              target={"_blank"}
              href={NAIL_SHOP_INFO.ggMapUrl}
              rel="noreferrer"
              style={{minWidth:'50rem'}}
            >
              {`${NAIL_SHOP_INFO.address}${isMobile ? "" : "\n"}\n\n`}
            </a>
          </div>

          <div className={classes.openingTimeWrapper}>
            <h3>Opening Time</h3>
            <div className={classes.openingTimeContainer}>
              <div className={classes.openingTime}>
                <h3>{`${NAIL_SHOP_INFO.openingTime.openTime.weekdays}`}</h3>
                <h3>{`${NAIL_SHOP_INFO.openingTime.openTime.time}`}</h3>
              </div>
              <div className={classes.openingTime}>
                <h3>{`${NAIL_SHOP_INFO.openingTime.closeTime.weekdays}`}</h3>
                <h3>{`${NAIL_SHOP_INFO.openingTime.closeTime.time}`}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.contactContainer}>
          <h3>Contact Us</h3>
          <a
            href={`tel:${NAIL_SHOP_INFO.tel}`}
          >{`Hotline: ${NAIL_SHOP_INFO.phone}\n\n`}</a>
          <a
            href={`mailto:${NAIL_SHOP_INFO.email}`}
          >{`Email: ${NAIL_SHOP_INFO.email}`}</a>
        </div>

        <div className={classes.columnInfo}>
          <div
            className={`${classes.contactContainer} ${classes.marginBottom}`}
          >
            <h3>Follow Us</h3>
            <a
              target={"_blank"}
              href={NAIL_SHOP_INFO.instagram}
              rel="noreferrer"
            >
              <img alt={"insta"} src={images.instaIcon} />
            </a>
            <a
              target={"_blank"}
              href={NAIL_SHOP_INFO.facebook}
              rel="noreferrer"
            >
              <img alt={"insta"} src={images.fbIcon} />
            </a>
          </div>

          <div
            className="fb-page"
            data-href={NAIL_SHOP_INFO.facebook}
            data-tabs="timeline"
            data-width={isMobile ? "320" : "150"}
            data-height="100"
            data-small-header="false"
            data-adapt-container-width="false"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite={NAIL_SHOP_INFO.facebook}
              className="fb-xfbml-parse-ignore"
            >
              <a href={NAIL_SHOP_INFO.facebook}>{NAIL_SHOP_INFO.name}</a>
            </blockquote>
          </div>
        </div>

        <div className={classes.menuContainer}>
          {MENU_HEADER.map((item) => (
            <a className={classes.menuLink} href={item?.navigationKey}>
              {item.name}
            </a>
          ))}
        </div>
      </div>

      <div className={classes.creditFooter}>
        <h4>
          {`Copyright © 2024 ${NAIL_SHOP_INFO.name}. All rights reserved. Designed by `}
          <a target={"_blank"} href={SOLCO_INFO.facebook} rel="noreferrer">
            {`SOLCO Marketing Agency`}
          </a>
        </h4>
      </div>
    </div>
  );
};

export default FooterInfo;
