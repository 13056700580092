import { useEffect, useState } from "react";
import styled from "styled-components";
import { images } from "../../assets/img";
import classes from "./FloatingButton.module.css";
import { NAIL_SHOP_INFO } from "../../utils/Const";

export default function FloatingButton() {
  const onClickIcon = () => {
    window.open(NAIL_SHOP_INFO.instagramChatUrl);
  };

  return (
    <img
      src={images.instaIcon}
      className={`${classes.imageIcon}`}
      alt="instagram-icon"
      onClick={onClickIcon}
    />
  );
}
