import React, {useContext, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import classes from "./App.module.css";
import RemoteConfig from './services/RemoteConfig'
import AuthContext from "./store/auth-context";
import {useTranslation} from "react-i18next";
import Booking from "./components/Booking/Booking";
import PriceList from "./pages/PriceList/PriceList";
import AboutUs from './pages/AboutUs/AboutUs'
import Policy from "./pages/Policy/Policy";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {
    /* NOTE:
     * Solution for redirecting to 404 not found page
     * When hosting static static single page application and
     * go to hcl.com/abcxyc and refresh it will return 404 page not found
     * because it's a static single page application the hosting only know hcl.com
     * so we config for the host to know we're using static single page application
     * In Netlify for Example. We redirect to the index.html when we make request to the server
     * https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually/44467274#44467274
     * */
    const {setFetchActivateRemoteConfig} = useContext(AuthContext)
    const {i18n} = useTranslation();


    useEffect(() => {
        RemoteConfig.fetchAndActivateRemoteConfig().then(_result => {
            setFetchActivateRemoteConfig(true)
        }).catch(_err => {

        })

        return () => {
        };
    }, []);

    useEffect(() => {

    }, [i18n.resolvedLanguage]);

    return (
        <div className={classes.App}>
            <Routes>
                <Route path="*" element={<Navigate to="/" replace/>}/>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/booking" element={<Booking/>}/>
                <Route path="/services" element={<PriceList/>}/>
                <Route path="/about-us" element={<AboutUs/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path="/contact-us" element={<ContactUs/>}/>
            </Routes>
        </div>
    );
}

export default App;
