export const images = {
    flagUk: require('./flag-uk.png'),
    flagVn: require('./flag-vn.png'),
    fbIcon: require('./fb-icon.png'),
    messengerIcon: require('./messenger-icon.png'),
    instaIcon: require('./insta-icon.png'),
    pricing: require('./pricing.jpg'),
    nailService: require('./nail-service.png'),
    nailService2: require('./nail-service2.png'),
    nailService3: require('./nail-service3.png'),
    nailService4: require('./nail-service4.png'),
    leftArrow: require('./left-arrow.png'),
    main_logo:  require('./main-logo.png'),
    header_primary: require('./header_primary.jpg'),
    header_primary02: require('./header_primary02.jpg'),
    banner01: require('./banner01.jpg'),
    banner02: require('./banner02.jpg'),
    banner03: require('./banner03.jpeg'),
    nail01: require('./nail01.png'),
    nail02: require('./nail02.png'),
    nail03: require('./nail03.png'),
    nail04: require('./nail04.png'),
    nail05: require('./nail05.png'),
    nail06: require('./nail06.png'),
    nail07: require('./nail07.png'),
    nail08: require('./nail08.png'),
    nail09: require('./nail09.png'),
    nail10: require('./nail10.png'),
    nail11: require('./nail11.png'),
    nail12: require('./nail12.png'),
    popup: require('./popup.jpg'),
    pedicure: require('./pedicure.jpg')
};
