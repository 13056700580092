import React from "react";
import classes from "./Contacts.module.css";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const Contacts = (props) => {
  return (
    <div ref={props?.contactsRef} className={classes.contacts}>
       <iframe
        title="google-map"
        className={classes.iframe}
        src={NAIL_SHOP_INFO.ggMapIframeUrl}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      
      <div className={classes.contactsContainer}>
        <h1>{"CONTACT"}</h1>

        <a
          target={"_blank"}
          rel="noreferrer"
          href={`tel:${NAIL_SHOP_INFO.tel}`}
        >{`PHONE: ${NAIL_SHOP_INFO.phone}`}</a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`mailto:${NAIL_SHOP_INFO.email}`}
        >{`EMAIL: ${NAIL_SHOP_INFO.email}`}</a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >{`ADDRESS: ${NAIL_SHOP_INFO.address}\n `}</a>
        <BookNowBtn
          onClick={() => window.open(NAIL_SHOP_INFO.ggMapUrl, "_blank")}
          isShowArrow={false}
          title={"GET DIRECTION"}
          style={classes.bookNowBtn}
        />

        <h1>{"\nOPENING HOURS"}</h1>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >
          {`${NAIL_SHOP_INFO.openingTime.openTime.weekdays}: ${NAIL_SHOP_INFO.openingTime.openTime.time}`}
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >
          {`${NAIL_SHOP_INFO.openingTime.closeTime.weekdays}: ${NAIL_SHOP_INFO.openingTime.closeTime.time}`}
        </a>

        <div>
          <a target={"_blank"} href={NAIL_SHOP_INFO.instagram} rel="noreferrer">
            <img
              className={classes.contactsContainerImg}
              alt={"insta"}
              g
              src={images.instaIcon}
            />
          </a>
          <a target={"_blank"} href={NAIL_SHOP_INFO.facebook} rel="noreferrer">
            <img
              className={`${classes.contactsContainerImg} ${classes.marginLeftFBIcon}`}
              alt={"facebook"}
              src={images.fbIcon}
            />
          </a>
        </div>
        <BookNowBtn isShowArrow={false} style={classes.bookNowBtn} />
      </div>
    </div>
  );
};

export default Contacts;
