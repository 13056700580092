import React from "react";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { MENU_HEADER, NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import { t } from "../../locales/i18n";
import BookNowBtn from "../BookNowBtn";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import Menu from "../Menu/Menu";
import { menuHeaderHandler } from "../../navigation/MenuHeaderHandler";

const Header = (props) => {
  const {
    introductionRef,
    contactsRef,
    serviceRef,
    selected,
  } = props || {};
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.introHeader}>
      <div className={classes.headerIntroContainer}>
        <h2>{isMobile ? NAIL_SHOP_INFO.name : "TK Nails"}</h2>
        {!isMobile && <BookNowBtn style={classes.bookNowBtnStyle}/>}
      </div>

      {isMobile ? (
        <Menu
          selected={selected}
          introductionRef={introductionRef}
          serviceRef={serviceRef}
          contactsRef={contactsRef}
        />
      ) : (
        <div className={classes.menuItem}>
          {MENU_HEADER.map((item, index) => {
            const { name, navigationKey } = item || {};
            return (
              <h4
                key={`${name}_${index}`}
                onClick={() => menuHeaderHandler(navigationKey, navigate)}
                className={
                  selected === navigationKey ? classes.selectedText : ""
                }
              >
                {name}
              </h4>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Header;
