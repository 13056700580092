// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from "firebase/remote-config";
import React from "react";
import Helpers from "../utils/Helpers";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCGoKP6mUQ0SsYyZHiBwCmzsafmGDbopk",
  authDomain: "tk-nails.firebaseapp.com",
  projectId: "tk-nails",
  storageBucket: "tk-nails.appspot.com",
  messagingSenderId: "376025603841",
  appId: "1:376025603841:web:7ac50fd45fd14108080500",
  measurementId: "G-6YMRE8SLE9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = Helpers.isDevelopment
  ? 60000
  : 600000;

const fetchAndActivateRemoteConfig = () => {
  return new Promise((resolve, reject) => {
    fetchAndActivate(remoteConfig)
      .then((a) => {
        resolve(a);
        console.log("Firebase fetch and activate successfully!!");
      })
      .catch((err) => {
        reject(err);
        console.log("Firebase fetch and activate FAILED!!");
      });
  });
};

const getConfigValue = (key) => {
  return Helpers.parseJson(getString(remoteConfig, key));
};

export default { remoteConfig, fetchAndActivateRemoteConfig, getConfigValue };
